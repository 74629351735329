import React, { useEffect, useState } from "react";
import ComponenetHeader from "../component/common/ComponenetHeader";
import BlogService from "../service/BlogService";
import imageService from "../service/ImageServie";
import { Helmet } from "react-helmet";

const BlogListingPage =()=>{

   const [blogs, setBlogs] = useState([]);
   const [loading, setLoading] = useState(true);
   const [page, setPage] = useState(0);
   const [size, setSize] = useState(9);
   const [totalPages, setTotalPages] = useState(1); // Total number of pages


   useEffect(() => {
      const fetchBlogs = async () => {
         try {
            const data = await BlogService.getAllBlogs(page, size);
            setBlogs(data.content); // Assuming the response contains a `blogs` array
            setTotalPages(data.totalPages);
         } catch (error) {
            console.error('Error fetching Blogs:', error);
         } finally{
            setLoading(false);
         }
      };

      fetchBlogs();
   }, [page, size]); 

   const handlePreviousPage = () => {
      if (page > 0) {
          setPage(page - 1);
      }
   };

   const handleNextPage = () => {
      if (page < totalPages - 1) {
         setPage(page + 1);
      }
   };

   // Function to create a slug from a given string
   const createSlug = (title) => {
   return title
      .toLowerCase() // Convert to lowercase
      .trim() // Trim leading/trailing spaces
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/--+/g, '-') // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
   };

    return(<>
         <Helmet>
            <title>Explore Our Blogs: Insights on Truck, Trailer, Reefer, Tire Repair & Towing | Online road services</title>            
            <meta name="description" content={`Dive into our informative blogs on Truck, Trailer, Reefer, Tire Repair, and Towing services for tips and insights to keep you informed`} />

            <meta property="og:title" content={`Explore Our Blogs: Insights on Truck, Trailer, Reefer, Tire Repair & Towing | Online road services`} />
            <meta property="og:description" content={`Dive into our informative blogs on Truck, Trailer, Reefer, Tire Repair, and Towing services for tips and insights to keep you informed`} />
        </Helmet>

        <ComponenetHeader
           name={'Blogs'}
        />

      <section className="blogPage_section">
        <div className="container"> 
           <div className="row">

            <div className="section-title col-md-12 mb-4">
                <p>- Our Blog -</p>
                <h2>Latest Blogs</h2>
            </div>

            {/* Dynamically render blogs */}
            {loading ? (                    
                <div className="d-flex justify-content-center align-items-center" style={{ height: '40vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <span className="ms-3">Loading, Please wait...</span>
                </div>
            ) :(
               blogs.map((blog) => (
                  <div className="col-md-4" key={blog.id}>
                     <div className="blog_content"> 
                           <img
                              src={`${imageService.getBlogImage}${blog.listingImageName}`}
                              alt={blog.listingImageName}
                              className="img-fluid b-image"
                           />
                           <span className="b-date">
                           {new Date(blog.createdAt).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: '2-digit'
                           })}
                           </span> 
                           <h2 className="b-title">
                              <a href={`/blog/${blog.id}/${createSlug(blog.title)}`}>
                                 {blog.title}
                              </a>
                           </h2>
                           <p className="b-description">{blog.content.replace(/<\/?[^>]+(>|$)/g, "")}</p>
                           <a className="b-btn" href={`/blog/${blog.id}/${createSlug(blog.title)}`}>
                              Read More
                           </a>
                     </div>
                  </div>
               ))
            )}

            {/* Pagination Controls */}
            <div className="col-md-12 mt-2 d-flex justify-content-end">
                <button
                    className="btn btn-secondary mr-3"
                    onClick={handlePreviousPage}
                    disabled={page === 0} // Disable if on the first page
                >
                    Previous
                </button>

                <button
                    className="btn btn-secondary"
                    onClick={handleNextPage}
                    disabled={page === totalPages - 1} // Disable if on the last page
                >
                    Next
                </button>
            </div>

           </div>
        </div>
      </section>  


      </>
    );
};

export default BlogListingPage;