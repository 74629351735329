import React from "react";

const StickeyContent=({formatedSubcategory, formattedLoaction})=>{

    return(
        <>
            <section className="sticky_content_section">
            <div className="container">
            <div className="row">
            <div className="sticky_content_text text-left">
                <h4 >
                OnlineRoadServices - Your Go-To Platform for {formatedSubcategory} Services Near You
                </h4>
                <p>
                Truckers have always needed reliable <strong>{formatedSubcategory}</strong>, truck maintenance, truck stops, and emergency roadside services to keep them moving. Breakdowns are never easy, but don’t worry if you find yourself stuck on the side of the road. We understand how frustrating it can be, and finding <strong>nearby repair shops</strong> quickly becomes essential. OnlineRoadServices is dedicated to helping truck drivers and others locate the nearest repair shops with ease.
                As an online platform, OnlineRoadServices delivers accurate and trustworthy information about local repair shops and service providers. Whether you need truck repair, trailer repair, towing, or reefer repair in <strong>{formattedLoaction}</strong> we’ve got you covered. Our platform offers a well-organized, user-friendly interface to ensure you can quickly find exactly what you’re looking for. In emergencies, we’re here to guide you with reliable information you can depend on.
                </p>
                <h4 >
                Finding the Nearest Repair Shops Made Easy
                </h4>
                <p>
                <strong>OnlineRoadServices</strong> was created to simplify the search for <strong>{formatedSubcategory}</strong> services in <strong>{formattedLoaction}</strong>. Designed with drivers in mind, the platform is for anyone who needs fast, accurate information. Just select the type of service you need and enter your location, and you’ll instantly get a list of the closest <strong>{formatedSubcategory}</strong> options. You'll also find contact details to make your experience even more seamless.
                At <strong>OnlineRoadServices</strong>, we are committed to providing information you can trust. Our extensive database of heavy-duty truck repair shops is continually updated and verified from multiple sources. In case of emergencies, <strong>OnlineRoadServices</strong> is your go-to for reliable data. The platform is simple to use, making it a resource you can trust whenever you’re in need.
                </p>
                <h4 >
                Services Wherever and Whenever You Need Them
                </h4>
                <p>
                <strong>OnlineRoadServices</strong> offers a wide range of services throughout the United States. We understand how stressful it can be to find a <strong>{formatedSubcategory}</strong> service in <strong>{formattedLoaction}</strong>, but with <strong>OnlineRoadServices</strong>, that stress disappears. Select the service you need and your location, and we’ll show you the nearest options, arranged by proximity. You can even customize your search radius for more results, giving you the flexibility to choose the best option for your needs.
                We know that time is crucial in an emergency, so <strong>OnlineRoadServices</strong> ensures you only find accurate, dependable data. You’ll never have to worry about finding service in unfamiliar areas—<strong>OnlineRoadServices</strong> has your back. 
                </p>
                <h4 >
                The OnlineRoadServices Mobile App – Quick Help, Right at Your Fingertips
                </h4>
                <p>
                When your vehicle breaks down, it’s never convenient, but <strong>OnlineRoadServices</strong> makes it easier to handle. Whether it’s due to <strong>bad weather</strong> or a <strong>mechanical issue</strong>, you don’t have to wait long for assistance. Just open the <strong><a href="https://apps.apple.com/in/app/online-road-service/id6444897798">OnlineRoadServices mobile app</a></strong>, and within seconds, you’ll find the nearest <strong>{formatedSubcategory}</strong> options in <strong>{formattedLoaction}</strong>, or wherever you are. The app provides all the information you need to get your truck, trailer, or semi-truck back on the road.
                Download our app from the <strong><a href="https://play.google.com/store/apps/details?id=com.ors.ors&pli=1">Google Play Store</a></strong> or <strong><a href="https://apps.apple.com/in/app/online-road-service/id6444897798">iOS</a></strong>, and enjoy peace of mind knowing help is just a few taps away. With OnlineRoadServices, you’re never far from the assistance you need.
                </p>
                <h4 >
                Emergency Roadside Assistance at Your Fingertips
                </h4>
                <p>
                Emergencies don’t come with warnings, and when they strike, you need quick and reliable help. <strong>OnlineRoadServices</strong> is your answer to locating <strong>emergency roadside assistance</strong> in <strong>{formattedLoaction}</strong> without the hassle. With just a few clicks, you can find the closest help and get back on the road as soon as possible.
                Our platform is straightforward and easy to navigate, ensuring you get the assistance you need with minimal stress. For even greater convenience, the <strong><a href="https://apps.apple.com/in/app/online-road-service/id6444897798">OnlineRoadServices mobile app</a></strong> provides roadside service details right at your fingertips. While we can’t prevent breakdowns, we can make sure you have the support you need during those stressful times.
                </p>
            </div>
            </div>
            </div>
            </section>
        </>
    );
};

export default StickeyContent;