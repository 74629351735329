import React, { useState } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import imageService from '../../service/ImageServie';

function AdCard({ advertisement, onViewDetails }) {

    const [showNumber, setShowNumber] = useState(false);

    const formatPhoneNumber = (phoneNumber) => {
        // Format phone number as (XXX) XXX-XXXX
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      };

      const handlePhoneClick = (phone) => {
        if (showNumber) {
           // Initiate a call (this will only work if your environment supports such functionality)
          window.location.href = `tel:${phone}`; }
        else {
          // Make the phone number visible
          setShowNumber(true);
        }
      };

    return (<>
        <Card className="mb-3 internal_ads_row col-md-11 px-0 border-0 shadow">
            <Row className='mx-0 justify-content-center'>
            {advertisement.imageName && (
                <Col md={3} className='p-0 align-content-around ad_banner'>
                <Card.Img variant="top"
                    src={`${imageService.getAdsImage}${advertisement.imageName}`}
                    alt={advertisement.companyName}
                    />
                </Col>
            )}
                <Col md={advertisement.imageName ? 7 : 10}>

                    <Card.Body className='p-0 planCard-style text-left'>
                          <Row>
                            <Col md={12} className='planInfo-style'>
                              <div className='row'>
                                <div className='col-md-6'>
                                  <div className='cardText'>
                                    <span className='ad_miles mb-0'>{advertisement.distance.toFixed(1)} miles</span>
                                    <Card.Title>{advertisement.companyName}</Card.Title>
                                    <p className='ad_address mb-0'><i className="fas fa-location-dot"></i> {advertisement.city}, {advertisement.state}</p>
                                    <p className='ad_email mb-2'><i className="fa fa-envelope"></i> {advertisement.email  || 'Not Available'}</p>
                                    {/* <p className='ad_website mb-2'>
                                      <i className="fa fa-globe"></i> <a href={advertisement.website} target="_blank" rel="noopener noreferrer">{advertisement.website}</a>
                                    </p> */}
                                  </div>

                                  <div className="ad_description">
                                    <p className='mb-0'>{advertisement.description}</p>
                                  </div>

                                </div>

                                <div className='col-md-6'>
                                  <div className='ad_services'>
                                    <h6 className='ad_worktype'>Work Type : <b>{advertisement.shopType || 'Not Available'}</b></h6>
                                    <ul className='ad_serviceList'>
                                    {advertisement.services.slice(0, 4).map((service, index) => (
                                      <li key={index}>{service}</li>
                                    ))}
                                    </ul>
                                  </div>
                                </div>

                                {advertisement.planId === 3 &&(
                                <div className='col-md-12'>
                                    <div className='special_ad_shimmer'>
                                    <h6 className='blink_me'>
                                      <span className='sale_animation'>Highlights :</span>
                                     { advertisement.highlight || 'Get in touch for special discount...'}</h6>
                                    </div>
                                </div>
                                )}

                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                </Col>

                <Col md={2} className='p-0'>
                    <div className="planPrice-style text-center align-content-around">
                    <h4>For More Details </h4>
                    <button className='btn showNumber' type="button" onClick={()=>handlePhoneClick(advertisement.phone)}><i className="fa fa-phone"></i> {showNumber ? formatPhoneNumber(advertisement.phone) : "Call Us"}</button>
                    <h6 className='ad_hours'><i className="far fa-clock"></i> {advertisement.workingHours}</h6>
                    <Button className='planBtn' variant="primary" onClick={() => onViewDetails(advertisement.id, advertisement.companyName)}>View Detail</Button>
                    </div>
                </Col>

            </Row>
        </Card>

    </>

    );
}

export default AdCard;