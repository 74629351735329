import React from 'react';
import logo from '../../assets/logo.png';
import { Navbar, Nav, Container } from 'react-bootstrap';

const Header = () => {
  return (
    <Navbar bg="white" variant="light" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/home"> 
          <img className='logo'
            src={logo}
            alt="OnlineRoadService Logo"
             // Adjust size as needed
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto cust-nav"> 
            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/plans">plans</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
            <Nav.Link href="/blogs">Blog</Nav.Link>
            <Nav.Link className='app_btn' href="/app"><i className="fa fa-mobile-alt"></i> &nbsp;Download App</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
