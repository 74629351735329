import BaseUrl from "../Util/BaseUrl";

const getPlanImage = BaseUrl.baseUrl+'image/plansimage/';

const getAdsImage = BaseUrl.baseUrl+'image/adsimage/';

const getSaleBanner = BaseUrl.baseUrl+'image/salebanner/';

const getBlogImage = BaseUrl.baseUrl+'image/blogsimage/'

const imageService ={
 getPlanImage,
 getAdsImage,
 getSaleBanner,
 getBlogImage

};

export default imageService