import axios from 'axios';
import BaseUrl from '../Util/BaseUrl';

const API_URL = BaseUrl.baseUrl+"api/plans";

const getAllPlans = async () => {
    try {
        const response = await axios.get(API_URL);
        return response.data;
    } catch (error) {
        console.error('Error getting Plan:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data : 'Failed to getting Plan');
    }
};

const PlanService = {
    getAllPlans
};

export default PlanService;