import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col, InputGroup, Dropdown, Spinner  } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SubcategoryService from '../../service/SubcategoryService';
import LocationService from '../../service/LocationService';
import icon1 from '../../assets/icon-one.png';
import icon2 from '../../assets/icon-two.png';
import icon3 from '../../assets/icon-three.png';
import icon4 from '../../assets/icon-four.png';
import icon5 from '../../assets/icon-five.png';
import icon6 from '../../assets/icon-six.png';

const SearchSection = () => {
  const [location, setLocation] = useState('');
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [showLocationSuggestions, setShowLocationSuggestions] = useState(false);
  const [showSubcategory, setShowSubcategory] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {

    fetchData();
  }, []);

  const fetchData = async ()=>{
    try {
        const subcategoriesResponse = await SubcategoryService.getSubcategoriesByCategory();
        setSubcategories(subcategoriesResponse);
    } catch (error) {
        console.error('Error fetching subcategories:', error);
    }
    };

  const handleLocationChange = async (e) => {
    const newLocation = e.target.value;
    setLocation(newLocation);

    if (newLocation.length >= 3) {
      setLoading(true);
      setShowLocationSuggestions(true);
      try {
        const response = await LocationService.getLocationByName(newLocation);
        setLocationSuggestions(response);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching location suggestions:', error);
        setLoading(false);
        setShowLocationSuggestions(false);
      }
    } else {
      setShowLocationSuggestions(false);
      setLoading(false);
    }
  };

  const handleLocationSelect = (selectedLocation) => {
    setLocation(selectedLocation.location); // For display in the input field
    setShowLocationSuggestions(false);
  };

  const handleSearch = () => {
    const selectedSubcategoryData = subcategories.find(sub => sub.name === selectedSubcategory);
    if (selectedSubcategoryData) {
      sessionStorage.setItem("subcategoryId", selectedSubcategoryData.id);
    }

    const formattedSubcategory = selectedSubcategory.toString().trim().replace(/\s+/g, '-');
    const searchlocation = location.trim().replace(/,\s*/g, '-');
    sessionStorage.removeItem("shopType");

    const url = `/service-near-me/${formattedSubcategory}/${searchlocation}`;
    navigate(url);
  };

  const handleLinks = (subcategory) => {
    const selectedSubcategoryData = subcategories.find(sub => sub.name === subcategory);
    if (selectedSubcategoryData) {
      sessionStorage.setItem("subcategoryId", selectedSubcategoryData.id);
    }

    const formattedSubcategory = (selectedSubcategoryData?.name).toString().trim().replace(/\s+/g, '-');
    sessionStorage.removeItem("shopType");

    const searchlocation =  ['Phoenix-AZ-USA', 'Fresno-CA-USA', 'Dallas-TX-USA', 'Washington-DC-USA', 'Beaumont-CA-USA', 'Dallas-GA-USA', ]
    const randomlocation = searchlocation[Math.floor(Math.random() * searchlocation.length)];

    const url = `/service-near-me/${formattedSubcategory}/${randomlocation}`;
    navigate(url);
  };

  const handleSubcategorySelect = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setShowSubcategory(false);
  };

  return (<>
    <section className="home_search">
      <Container>
      <Row className="justify-content-center">
      <Col md={10} sm={12} className='px-md-0'>
      <h3 className="text-left">Find Road Services</h3>
      <h5 className="text-left mb-2">Near You Without Any Hassle</h5>
      <p className="search_cont text-left">Stop worrying on the road, have your backup plan ready.
      Fast and Reliable help for your emergency.</p>
      </Col>
      </Row>
      <Form onSubmit={handleSearch}>
        <Row className="align-items-center justify-content-center search_input">
          <Col md={4} sm={12} className="category_search">
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Choose Service"
                value={selectedSubcategory}
                onClick={() => setShowSubcategory(!showSubcategory)}
                readOnly
              />
            </InputGroup>
            {showSubcategory && (
              <Dropdown.Menu show className="w-100">
                {subcategories.map((subcategory) => (
                  <Dropdown.Item
                    key={subcategory.id}
                    onClick={() => handleSubcategorySelect(subcategory.name)}
                  >
                {subcategory.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            )}
          </Col>
          <Col md={4} sm={12} className="location_search">
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Enter your city, state"
                value={location}
                onChange={handleLocationChange}
                className="form-control"
              />
              <Button
                variant="outline-secondary"
                //onClick={handleLocation}
              >
                <i className="fas fa-location-dot"></i>
              </Button>
            </InputGroup>
            {showLocationSuggestions && (
              <Dropdown.Menu show className="w-100">
                {loading ? (
                  <div className="spinner-container d-flex justify-content-center mt-4">
                    <Spinner animation="border" role="status" size="m"/>
                  </div>) : (
                    <>
                      {locationSuggestions.length > 0 ? (
                        locationSuggestions.map((suggestion, index) => (
                        <Dropdown.Item key={index} onClick={() => handleLocationSelect(suggestion)}>
                          {suggestion.location}
                        </Dropdown.Item>
                      ))
                      ) : (
                        <Dropdown.Item disabled>No results found</Dropdown.Item>
                      )}
                    </>
                )}
              </Dropdown.Menu>
            )}
          </Col>
          <Col md={2} sm={12} className="search_btn">
            <Button
              variant="primary"
              className="btn-block"
              onClick={handleSearch}
            >
              <i className="fa fa-search"></i>  Search
            </Button>
          </Col>
        </Row>
      </Form>
      </Container>
    </section>

     <section className='searchIcon_section'>
      <Container>
      <Row className="justify-content-center">
      <Col className='search_icon px-0' md={12} sm={12}>
      <ul>
        <li> <a onClick={() => handleLinks("Truck Repair")}><img src={icon1} className='img-fluid' alt={icon1}/><span>Truck Repair</span></a></li>
        <li> <a onClick={() => handleLinks("Trailer Repair")}><img src={icon2} className='img-fluid' alt={icon2}/><span>Trailer Repair</span></a></li>
        <li> <a onClick={() => handleLinks("Tire Repair and Sales")}><img src={icon3} className='img-fluid' alt={icon3}/><span>Tire Repair</span></a></li>
        <li> <a onClick={() => handleLinks("Towing and Recovery")}><img src={icon4} className='img-fluid' alt={icon4}/><span>Towing Service</span></a></li>
        <li> <a onClick={() => handleLinks("Reefer Repair")}><img src={icon5} className='img-fluid' alt={icon5}/><span>Reefer Repair</span></a></li>
        <li> <a href="#" ><img src={icon6} className='img-fluid' alt={icon6}/><span>Driving School</span></a></li>
      </ul>
      </Col>
      </Row>
      </Container>
     </section>

    </>
  );
};

export default SearchSection;
